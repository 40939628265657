@font-face {
  font-family: "Love";
  src: url("./fonts/Love.ttf")format("truetype");
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

@media (min-width: 250px) {

  #typewriter {
    color: blue;
  }

  .App {
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 99.7vw;
    overflow: hidden;
  }

  .App-header {
    background-color: #f9f;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .button {
    background-color: #b5dff9;
    color: #f9f;
    margin: 5px;
    padding: 10px;
    border: 2px solid #34677b;
    border-radius: 5px;
    font-family: "Love";
    cursor: pointer;
    font-size: 48px;
    font-weight: bold;
    width: 275px;
    height: 80px;
  }

  #main-content {

    height: 33vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .logo {
    height: 7vh;
  }

  #logo-text-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    background-color: white;
    color: #f9f;
    border-radius: 16px;
    padding: 16px;
    font-size: 4vh;
    font-family: "Love";
  }

  #upper-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 20vh;
    width: 100vw;
  }

  a {
    color: inherit;
  }

  a:visited {
    color: inherit;
  }

  #info-bar {
    border-right: 5px none white;

    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-family: "Love";
    color: #f9f;
    padding: 15px;
    position: absolute;
    font-weight: 900;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100vh;
    width: 20vw;
    font-size: 3.5vh;
  }


}

@media (min-width: 1200px) {

  .App {
    text-align: center;
  }

  .button {
    background-color: #b5dff9;
    color: #ffddf8;
    margin: 5px;
    padding: 10px;
    border: 2px solid #34677b;
    border-radius: 5px;
    font-family: "Love";
    cursor: pointer;
    font-size: 48px;
    font-weight: bold;
    width: 275px;
    height: 80px;
  }

  #main-content {

    height: 33vh;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .logo {
    height: 10vh;
  }

  #logo-text-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    color: #ffddf8;
    border-radius: 16px;
    padding: 16px;
    font-size: 10vh;
    font-family: "Love";
  }

  #upper-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 20vh;
    width: 100vw;
  }

  a:visited {
    color: inherit;
  }

}
